import React from 'react';

function Dashboard() {
  return (
    <div className="content" style={{ marginLeft: '260px', padding: '20px' }}>
      <div className="container mt-4">
      <h1>Welcome to the ImpactLink Dashboard</h1>
            <p>Welcome to ImpactLink, your ultimate YouTube search and analytics platform. Our goal is to provide you with the tools and insights necessary to navigate the vast world of YouTube content effectively. Whether you're a content creator, marketer, educator, or researcher, ImpactLink is designed to help you find, analyze, and utilize YouTube videos to their fullest potential.</p>
            <p>Our platform offers a range of features including advanced search capabilities, comprehensive analytics, and user-friendly interfaces to ensure you get the most out of your YouTube experience. We are committed to continuously improving and expanding our services to meet your evolving needs.</p>
            <p>At ImpactLink, we believe in the power of knowledge and the importance of easy access to valuable information. Join us on this journey to explore and harness the potential of YouTube content for your personal or professional growth.</p>

            <div class="mt-4">
                <h2>Social Listener Website</h2>
                <p>The Social Listener feature of ImpactLink is designed to provide in-depth analytics and insights into social media trends and user engagement. By leveraging YouTube's vast repository of content, we offer tools to analyze video metadata, comments, and user interactions, helping you to stay ahead of trends and understand audience sentiments.</p>
                <p>Our platform enables users to conduct comprehensive searches across YouTube, filtering results based on relevance, view count, rating, and more. This functionality is particularly useful for researchers, marketers, and content creators looking to gather data, track performance, and refine their strategies.</p>
                <p>With ImpactLink, you can gain a deeper understanding of your audience and the content they engage with. Utilize our analytics tools to uncover valuable insights, optimize your content, and make informed decisions. Whether you're tracking the latest trends, analyzing competitors, or seeking inspiration for your next video, ImpactLink is your go-to resource for all things YouTube.</p>
        </div>
        <button type="button" className="btn btn-primary mt-4" onClick={() => window.location.href='/search'}>
          Get Started
        </button>
        <div id="results" className="mt-4"></div>
      </div>
    </div>
  );
}

export default Dashboard;
